var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Drawer",
        {
          attrs: {
            title: _vm.title,
            closable: false,
            visible: _vm.visible,
            "z-index": 5,
            size: "small",
          },
          on: { close: _vm.onClose, onSubmit: _vm.onsubmit },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("UserInfo", { attrs: { "user-info": _vm.userInfo } }),
              _c(
                "div",
                { staticClass: "setForm" },
                [
                  _vm.socialStatus === 1
                    ? _c("AddFormVue", {
                        ref: "social",
                        attrs: {
                          title: "社保",
                          "insure-classify": "social",
                          "is-flag": false,
                          "disabled-list": _vm.disabledList,
                          "form-data": _vm.socialData,
                        },
                      })
                    : _vm._e(),
                  _vm.medicalStatus === 1
                    ? _c("AddFormVue", {
                        ref: "medical",
                        attrs: {
                          title: "医保",
                          "insure-classify": "medical",
                          "is-flag": false,
                          "disabled-list": _vm.disabledList,
                          "form-data": _vm.medicalData,
                        },
                      })
                    : _vm._e(),
                  _vm.housingStatus === 1
                    ? _c("AddFormVue", {
                        ref: "housing",
                        attrs: {
                          title: "公积金",
                          "insure-classify": "housing",
                          "is-flag": false,
                          "disabled-list": _vm.disabledList,
                          "form-data": _vm.housingData,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }